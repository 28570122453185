/* eslint-disable */
// import Vue from "vue";
import axios from "axios";
// import store from "@/store";
// import notification from "ant-design-vue/es/notification";
import { VueAxios } from "./axios";
// import { ACCESS_TOKEN } from "@/store/mutation-types";
import { JSEncrypt } from "./jsencrypt.js";
const publicKey =
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCZqKTrHvs0nK8Xo0MPlf8wuuPdrbZ11XP1j+95pS60axwo+DWr0JQpVBJTjEr0zz8SvWe9LiefDguBTwdmjBrS2hvP+dnk+WKtmlXWXpOn8DY9Tzpepdnpk6BMIBjKu3SSWHbOqcimBFj19uPZ8yXO+hx7UUnZZiecqf6FgM1+LwIDAQAB";
// 创建 axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL, // api base_url
    timeout: 6000 // 请求超时时间
});

const err = error => {
    if (error.response) {
        // const data = error.response.data;
        const token = ""; //ACCESS_TOKEN;
        if (error.response.status === 403) {
            // notification.error({
            //     message: "Forbidden",
            //     description: data.message
            // });
        }
        if (error.response.status === 401) {
            // notification.error({
            //     message: "未授权",
            //     description: data.message || "Authorization verification failed"
            // });
            if (token) {
                // store.dispatch("Logout").then(() => {
                //     setTimeout(() => {
                //         window.location.reload();
                //     }, 1500);
                // });
            }
        }
        if (error.response.status === 500) {
            // notification.error({
            //     message: "Forbidden",
            //     description: data.message
            // });
        }
    }
    let originalRequest = error.config;
    if (
        error.code === "ECONNABORTED" &&
        error.message.indexOf("timeout") !== -1 &&
        !originalRequest._retry
    ) {
        // eslint-disable-next-line
        // notification.error({
        //     message: "请检查网络再重新连接",
        //     description: error.message
        // });
    }
    return Promise.reject(error);
};

export function rsaEncrypt(str) {
    // RSA加密了
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    let urlKey = str.split("?");
    urlKey = urlKey[0];
    var encrypted = encrypt.encrypt(urlKey);
    return { urlKey, antiKey: encrypted };
}

// request interceptor
service.interceptors.request.use(async config => {
    // const token = ""; //Vue.ls.get(ACCESS_TOKEN);
    // if (token) {
    //     config.headers["Authorization"] = "bearer " + token; // 让每个请求携带自定义 token 请根据实际情况自行修改
    //     const url = config.url;
    //     const { urlKey, antiKey } = await rsaEncrypt(url);
    //     config.headers["antiKey"] = antiKey;
    //     config.headers["urlKey"] = urlKey;
    // }
    const url = config.url;
    const { urlKey, antiKey } = await rsaEncrypt(url);
    config.headers["antiKey"] = antiKey;
    config.headers["urlKey"] = urlKey;
    return config;
}, err);

// response interceptor
service.interceptors.response.use(response => {
    if (
        (response.data && response.data.code === "200") ||
        response.data.code === 200
    ) {
        return response.data;
    } else {
        return Promise.reject(response.data);
    }
}, err);

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, service);
    }
};

export { installer as VueAxios, service as axios };
