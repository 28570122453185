import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "activity",
        meta: {
            keepAlive: false //true是保存缓存，false是不保存
        },
        component: () =>
            import(
                /* webpackChunkName: "activity" */ "../views/activity/index.vue"
            )
    },
    {
        path: "/serveCenter",
        name: "serveCenter",
        meta: {
            keepAlive: false //true是保存缓存，false是不保存
        },
        component: () =>
            import(
                /* webpackChunkName: "activity" */ "../views/activity/serveCenter.vue"
            )
    },
    {
        path: "/equity",
        name: "equity",
        meta: {
            keepAlive: false //true是保存缓存，false是不保存
        },
        component: () =>
            import(
                /* webpackChunkName: "activity" */ "../views/activity/equity.vue"
            )
    },
    {
        path: "/agreement",
        name: "agreement",
        meta: {
            keepAlive: false //true是保存缓存，false是不保存
        },
        component: () =>
            import(
                /* webpackChunkName: "activity" */ "../views/activity/agreement.vue"
            )
    },
    // {
    //     path: "/test",
    //     name: "test1",
    //     meta: {
    //         keepAlive: true //true是保存缓存，false是不保存
    //     },
    //     component: () =>
    //         import(/* webpackChunkName: "test" */ "../views/test1/index.vue")
    // },
    // {
    //     path: "/demo",
    //     name: "demo",
    //     component: () =>
    //         import(/* webpackChunkName: "test" */ "../views/test1/test.vue")
    // }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
