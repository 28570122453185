import Vue from "vue";
import vuex from "vuex";

Vue.use(vuex);

export default new vuex.Store({
    state: {
        vuex_fakerInfo: null
    },
    mutations: {
        setInfo(state, info) {
            state.vuex_fakerInfo = info;
        }
    },
    actions: {},
    modules: {}
});
