import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import "lib-flexible";
import { Toast, Dialog, Overlay, Tab, Tabs, Button, Cell, CellGroup, Field, RadioGroup, Radio, Uploader  } from "vant";
import "vant/lib/toast/style";
import "vant/lib/dialog/style";
import "vant/lib/overlay/style";
import "vant/lib/tab/style";
import "vant/lib/tabs/style";
import "vant/lib/cell-group/style";
import "vant/lib/cell/style";
import "vant/lib/field/style";
import "vant/lib/radio-group/style";
import "vant/lib/radio/style";
import "vant/lib/uploader/style";
import { VueAxios } from "./utils/request";

Vue.use(VueAxios);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Overlay);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Uploader);

Vue.config.productionTip = false;
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
