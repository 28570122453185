<template>
	<div id="app">
		<keep-alive>
			<router-view v-if="isRouterAlive"></router-view>
		</keep-alive>
	</div>
</template>
<script>
	export default {
		name: "App",
		data() {
			return {
				isRouterAlive: true
			};
		},
		provide() { // 注册一个方法
			return {
				reload: this.reload
			};
		},
		methods: {
			reload() {
				this.isRouterAlive = false;
				this.$nextTick(function () {
					this.isRouterAlive = true;
				});
			}
		}
	};
</script>
<style lang="scss">
	body {
		background: #eee;
		/* font-family: PingFangSC-Medium, PingFang SC !important; */
	}

	* {
		margin: 0;
		padding: 0;
	}

	ul,
	li {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		-webkit-user-select: none;
		-moz-user-focus: none;
		-moz-user-select: none;
	}

	.van-tabs__nav--line .van-tab .van-tab__text span {
		color: #cc0000 !important;
	}
</style>